import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import { Link, graphql, useStaticQuery } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTiktok,
  faYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import ArticleBanner from "../components/ArticleBanner/ArticleBanner";
import FeaturedIn from "../components/FeaturedIn/FeaturedIn";
import Footer from "../components/Footer/Footer";
import RelatedArticles from "../components/RelatedArticles/RelatedArticles";

import "./AuthorPage.css";

import { GlobalStateContext } from "../context/GlobalContextProvider";

const AuthorProfileTemplate = ({ pageContext, data }) => {
  const context = React.useContext(GlobalStateContext);

  const { author, authorArticles } = pageContext;

  return (
    <Page>
      <TopBar showSearch={true} />
      <Section>
        <div className="author-page">
          <div>
            <div className="author-bio">
              <img
                src={author.image}
                alt="Author's Photo"
                className="author-photo"
              />
              <div className="author-info">
                <h4 className="author-name">
                  {author.fullName}
                  {" - "}
                  <span className="author-role">{author.role}</span>
                </h4>
                <div className="team-member-description">
                  {author.description.map((paragraph) => (
                    <p>{paragraph}</p>
                  ))}
                </div>
                <div>
                  {author.email && (
                    <a href={`mailto:${author.email}`} target="_blank">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ marginRight: 10, color: "#000", fontSize: 20 }}
                      />
                    </a>
                  )}
                  {author.youtube && (
                    <a href={author.youtube} target="_blank">
                      <FontAwesomeIcon
                        icon={faYoutube}
                        style={{ marginRight: 10, color: "#000", fontSize: 20 }}
                      />
                    </a>
                  )}
                  {author.linkedin && (
                    <a href={author.linkedin} target="_blank">
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        style={{ marginRight: 10, color: "#000", fontSize: 20 }}
                      />
                    </a>
                  )}
                  {author.tiktok && (
                    <a href={author.tiktok} target="_blank">
                      <FontAwesomeIcon
                        icon={faTiktok}
                        style={{ marginRight: 10, color: "#000", fontSize: 20 }}
                      />
                    </a>
                  )}
                  {author.facebook && (
                    <a href={author.facebook} target="_blank">
                      <FontAwesomeIcon
                        icon={faFacebookF}
                        style={{ marginRight: 10, color: "#000", fontSize: 20 }}
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {authorArticles && (
        <RelatedArticles title="My Articles" relatedArticles={authorArticles} />
      )}
      <FeaturedIn />
      <Footer />
    </Page>
  );
};

export default AuthorProfileTemplate;

export const Head = ({ pageContext }) => {
  const { author } = pageContext;
  const location = useLocation();

  return (
    <SEO
      index
      follow
      title={author.fullName}
      description={author.metaDescription}
      pathname={location.pathname}
    />
  );
};
